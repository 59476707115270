<template>
  <div>
    <div class="border-b-[0.5px] border-b-zinc-200">
      <div class="m-4 items-end justify-between gap-2 md:flex md:items-start">
        <div class="gap-2">
          <div class="flex items-center justify-start gap-2">
            <div
              id="dashboard-name"
              class="text-xl font-medium"
            >
              {{ dashboardObjectDeepClone.translateName
                ? $t('dashboards.name.' + dashboardObjectDeepClone.name)
                : dashboardObjectDeepClone.name }}
            </div>

            <UIcon
              v-if="dashboardObjectDeepClone.isDefault"
              id="dashboard-default-icon"
              name="i-mdi-home-outline"
              class="mb-0.5 !size-6 text-grey-blue"
            />
          </div>
          <div
            id="dashboard-subtitle"
            class="text-2xs font-semibold uppercase text-grey-blue"
          >
            {{ dashboardObjectDeepClone.rootOrganisationNodeName }}
          </div>
        </div>

        <DashboardActions
          v-model:dashboard="dashboardObjectDeepClone"
          :touchpoints="filteredTouchpoints"
          @update="updateDashboard($event)"
          @save="updateDashboard(undefined, true)"
        />
      </div>
    </div>

    <div v-if="computedTouchpoints && computedTouchpoints.length">
      <HubTabs
        v-if="dashboardObjectDeepClone?.config?.touchpointDisplayType == 'tabs'"
        :items="computedTouchpoints"
      >
        <template #header="{ item }">
          {{ item?.translateLabel ? $t('dashboards.touchpoint.name.' + item.label) : item.label }}
        </template>
        <template #item="{ item }">
          <DashboardTouchpoint
            :dashboard="dashboardObjectDeepClone"
            :touchpoint="(item as HubTouchpointConfig)"
            @update="$event => handleUpdateTouchpoint(item.id, $event)"
          />
        </template>
      </HubTabs>
      <DashboardTouchpoint
        v-for="touchpoint in computedTouchpoints"
        v-else
        :key="touchpoint.id"
        :dashboard="dashboardObjectDeepClone"
        :touchpoint="touchpoint"
        @update="$event => handleUpdateTouchpoint(touchpoint.id, $event)"
      />
    </div>
    <HubError
      v-else
      parent-classes="flex flex-col justify-center px-6 py-20 lg:py-30 lg:px-8 "
      :show-return-home="false"
      :custom-title="$t('dashboards.touchpoint.noTouchpoints.title')"
      :custom-message="$t('dashboards.touchpoint.noTouchpoints.message')"
    />

    <DashboardSavePrompt
      v-if="showSavePrompt"
      v-model="savePromptVisible"
      :can-edit-dashboard="canEditDashboard"
      :can-create-dashboard="canCreateDashboard"
      :dashboard="dashboardObjectDeepClone"
      @save="updateDashboard(undefined, true)"
    />
  </div>
</template>

<script setup lang="ts">
import * as jsonpatch from 'fast-json-patch'
import cloneDeep from 'lodash/cloneDeep'
import DashboardSavePrompt from '~/components/dashboard/DashboardSavePrompt.vue'
import type { PatchObject } from '~/types'
import type {
  HubDashboardBaseModal,
  HubTouchpointConfig
} from '~/types/configuration'

const props = defineProps<{ dashboardObject: HubDashboardBaseModal }>()

const { t } = useI18n()
const route = useRoute()
const touchpointStore = useTouchpointStore()
const sectionStore = useSectionStore()
const dashboardStore = useDashboardStore()
const { currentOrganisationTouchpoints } = storeToRefs(touchpointStore)
const { currentUnModifiedDashboard } = storeToRefs(dashboardStore)

const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)

// internal refs
const savePromptVisible = ref(true)
const dashboardObjectDeepClone = ref(cloneDeep(props.dashboardObject))
const filteredTouchpointIds = ref<Array<string>>([])
const filteredTouchpoints = ref<Array<HubTouchpointConfig>>([])

// init + check dashboard exists
await touchpointStore.fetchTouchpointForOrganisation(dashboardObjectDeepClone.value.rootOrganisationNodeId)
await sectionStore.fetchSectionForOrganisation(dashboardObjectDeepClone.value.rootOrganisationNodeId)
dashboardStore.fetchDashboardMetadata(dashboardObjectDeepClone.value.id)

// computed
const computedTouchpoints = computed(() => {
  return filteredTouchpoints.value.filter(t => {
    if (filteredTouchpointIds.value.length > 0) {
      return filteredTouchpointIds.value.includes(t.id) && t.isEnabled
    }

    return t.isEnabled
  }).sort((a, b) => a.ordinal - b.ordinal)
})
const canEditDashboard: ComputedRef<boolean> = computed(() => {
  return checkPermissions({
    permissions: props.dashboardObject.isOwner
      ? 'Dashboards.Default.Self.Update'
      : 'Dashboards.Default.Organisation.Update'
  })
})
const canCreateDashboard: ComputedRef<boolean> = computed(() => {
  return checkPermissions({
    permissions: 'Dashboards.Default.Self.Create'
  })
})
const showSavePrompt: ComputedRef<boolean> = computed(() => {
  if (!(canEditDashboard.value || canCreateDashboard.value) || !unsavedChanges.value.length) {
    return false
  }

  // check if the only changes are to isVisible or filterBarConfig or if there's something interesting
  const boringFields = ['isVisible', 'filterBarConfig']
  const somethingOtherThanIsVisibleOrFilterChanged = unsavedChanges.value.some(change => {
    const splitPath = change.path.split('/')
    const interestingChange = !splitPath.some(path => boringFields.includes(path))

    // if it thinks it's interesting, check if it's actually interesting
    if (interestingChange && typeof change.value == 'object' && change.value !== null) {
      return Object.keys(change.value).filter(key => !boringFields.includes(key) && key !== 'id').length > 0
    }

    return interestingChange
  })

  return somethingOtherThanIsVisibleOrFilterChanged
})
const unsavedChanges: ComputedRef<Array<PatchObject>> = computed(() => {
  if (!currentUnModifiedDashboard.value || !dashboardObjectDeepClone.value) {
    return []
  }

  const fieldsToInclude = ['config', 'name', 'translateName']
  const filteredUnModifiedDashboard = Object.keys(currentUnModifiedDashboard.value)
    .filter(key => fieldsToInclude.includes(key))
    .reduce((obj, key) => {
      // @ts-expect-error don't error
      obj[key] = currentUnModifiedDashboard.value[key]
      return obj
    }, {})
  const filteredDashboardEdits = Object.keys(dashboardObjectDeepClone.value)
    .filter(key => fieldsToInclude.includes(key))
    .reduce((obj, key) => {
      // @ts-expect-error don't error
      obj[key] = dashboardObjectDeepClone.value[key]
      return obj
    }, {})

  return jsonpatch.compare(filteredUnModifiedDashboard, filteredDashboardEdits)
})

// methods
function calculateTouchpoints(): Array<HubTouchpointConfig> {
  const touchpoints: Array<HubTouchpointConfig> = []

  if (!dashboardObjectDeepClone.value?.config) {
    return currentOrganisationTouchpoints.value
  }

  for (const touchpoint of currentOrganisationTouchpoints.value) {
    if (!dashboardObjectDeepClone.value.config.touchpoints) {
    // if there are no modifications to touchpoints in the dashboard config, show all touchpoints
      touchpoints.push(touchpoint)
      continue
    }

    const touchpointOverride = dashboardObjectDeepClone.value.config.touchpoints.find(t => t.id === touchpoint.id)

    if (!touchpointOverride) {
      touchpoints.push(touchpoint)
      continue
    }

    const customisedTouchpoint: HubTouchpointConfig = {
      ...touchpoint,
      ...touchpointOverride
    }

    touchpoints.push(customisedTouchpoint)
  }

  return touchpoints
}

async function handleUpdateTouchpoint(touchpointId: string, updateBody: Array<PatchObject>) {
  const squishTouchpoint = (updates: Array<PatchObject>): { [name: string]: unknown } => {
    const updateObject = updates.reduce((acc, su) => {
      acc[su.path] = su.value
      return acc
    }, {} as Record<string, unknown>)

    return updateObject
  }

  const dashboardTouchpointConfig = dashboardObjectDeepClone.value.config.touchpoints
  const getFinalUpdateBody = (): Array<PatchObject> => {
    if (!dashboardTouchpointConfig) {
      // if no touchpoints exist, must add the touchpoint
      const squishedTouchpoint = squishTouchpoint(updateBody)
      return [{
        op: 'add',
        path: '/config/touchpoints',
        value: [{
          id: touchpointId,
          ...squishedTouchpoint
        }]
      }]
    }

    // if touchpoints exist check for index of touchpoint
    const touchpointIndex = dashboardTouchpointConfig.findIndex(t => t.id === touchpointId)
    if (touchpointIndex === -1) {
      // touchpoint doesn't exist, must add the touchpoint
      const squishedTouchpoint = squishTouchpoint(updateBody)
      return [{
        op: 'add',
        path: '/config/touchpoints',
        value: [
          ...dashboardTouchpointConfig,
          {
            id: touchpointId,
            ...squishedTouchpoint
          }
        ]
      }]
    }

    // touchpoint exists, update the touchpoint
    return updateBody.map(b => ({
      ...b,
      path: `/config/touchpoints/${touchpointIndex}/${b.path}`
    }))
  }

  const remappedUpdateBody: Array<PatchObject> = getFinalUpdateBody()
  updateDashboard(remappedUpdateBody)
}

async function updateDashboard(patchBody?: Array<PatchObject>, saveInDatabase: boolean = false) {
  let patchObjectToBeSaved: Array<PatchObject> = patchBody || []
  if (patchBody && patchBody.length) {
    // @ts-expect-error patch body is of the correct type, just uses a limited (local) subset of the type
    const patchedDashboard = jsonpatch.applyPatch(dashboardObjectDeepClone.value, patchBody)
    dashboardObjectDeepClone.value = patchedDashboard.newDocument
  } else {
    patchObjectToBeSaved = unsavedChanges.value
  }

  if (!patchObjectToBeSaved.length) {
    useHubToast(
      {
        title: t('dashboards.notifications.updateFailed'),
        description: t('dashboards.notifications.noChanges')
      },
      'info'
    )
    return
  }

  if (!saveInDatabase) {
    dashboardStore.updateDashboard(
      route.params.id as string,
      props.dashboardObject.rootOrganisationNodeId,
      dashboardObjectDeepClone.value
    )
    return
  }

  if (!canEditDashboard.value) {
    // If the user doesn't have the permissions to save the dashboard, update the store rather than the API
    // and warn them that the changes won't be saved
    useHubToast(
      {
        title: t('dashboards.notifications.updateFailed'),
        description: t('dashboards.notifications.permissionDenied')
      },
      'warning'
    )
    return
  }

  try {
    const updatedDashboard = await $hubFetch<HubDashboardBaseModal>(`api/v4/organisations/${props.dashboardObject.rootOrganisationNodeId}/dashboards/${route.params.id}`, {
      method: 'PATCH',
      body: patchObjectToBeSaved
    })

    dashboardStore.updateUnModifiedDashboard(route.params.id as string, updatedDashboard)
    dashboardStore.updateDashboard(route.params.id as string, props.dashboardObject.rootOrganisationNodeId, updatedDashboard)

    useHubToast(t('dashboards.notifications.updated'), 'success')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    let description: string = ''

    if (error.message.includes('403')) {
      description = t('dashboards.notifications.permissionDenied')
    }

    useHubToast(
      {
        title: t('dashboards.notifications.updateFailed'),
        description
      },
      'danger'
    )
  }
}

// watchers
watch(
  () => props.dashboardObject,
  () => {
    dashboardObjectDeepClone.value = cloneDeep(props.dashboardObject)
    filteredTouchpoints.value = calculateTouchpoints()
  },
  {
    deep: true,
    immediate: true
  }
)
watch(
  [
    () => currentOrganisationTouchpoints.value,
    () => dashboardObjectDeepClone.value
  ],
  () => {
    filteredTouchpoints.value = calculateTouchpoints()
  },
  {
    deep: true
  }
)
watch(
  () => params.value,
  () => {
    if (!params.value.touchpointIds) {
      filteredTouchpointIds.value = []
      return
    }

    const touchpointIds = params.value.touchpointIds
    if (Array.isArray(touchpointIds)) {
      filteredTouchpointIds.value = touchpointIds as Array<string>
      return
    }

    filteredTouchpointIds.value = [touchpointIds as string]
  },
  {
    deep: true,
    immediate: true
  }
)
watch(
  () => unsavedChanges.value,
  () => {
    // If there are unsaved changes, and these change, show the save prompt
    if (unsavedChanges.value.length) {
      savePromptVisible.value = true
    }
  },
  {
    deep: true
  }
)
</script>
