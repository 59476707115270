<template>
  <div
    class="fixed bottom-0 left-0 m-4 max-w-xs rounded-lg border border-trublue bg-blue-7 p-4 text-grey-blue md:max-w-sm print:hidden"
    :class="{ hidden: !modelValue }"
  >
    <div
      id="save-prompt-text"
      class="mb-4 flex items-center justify-start gap-2 text-sm"
    >
      <UIcon
        name="i-mdi-info-outline"
        class="!size-5"
      />
      <div>{{ $t('dashboards.savePrompt') }}</div>
    </div>
    <div
      id="save-prompt-actions"
      class="flex flex-wrap justify-end gap-2"
    >
      <HubButton
        :label="$t('dismiss')"
        type="outlined"
        size="xs"
        @click="$emit('update:model-value', false)"
      />
      <HubButton
        v-if="canEditDashboard"
        :label="$t('save')"
        type="outlined"
        size="xs"
        @click="$emit('save'); $emit('update:model-value', false)"
      />
      <HubButton
        v-if="canCreateDashboard"
        :label="$t('dashboards.modal.saveAsNew.title')"
        type="outlined"
        size="xs"
        @click="showSaveAsNew = true; $emit('update:model-value', false)"
      />
    </div>

    <LazyDashboardUpdateNameModal
      v-if="canCreateDashboard"
      id="save-as-new-via-prompt"
      v-model="showSaveAsNew"
      :dashboard-id="dashboard.id"
      :dashboard-object="dashboard"
      :name="dashboard.name"
      :is-dashboard-shared="dashboard.groups.length > 0"
      :is-dashboard-name-translated="dashboard.translateName"
      save-as-new
    />
  </div>
</template>

<script setup lang="ts">
import type { HubDashboardBaseModal } from '~/types/configuration'

defineProps<{
  modelValue: boolean
  canEditDashboard: boolean
  canCreateDashboard: boolean
  dashboard: HubDashboardBaseModal
}>()

defineEmits<{
  (e: 'update:model-value', value: boolean): void
  (e: 'save'): void
}>()

const showSaveAsNew = ref(false)
</script>
