import type { HubSectionConfig } from '~/types/configuration'

export const useSectionStore = defineStore('section', () => {
  const organisationStore = useOrganisationStore()
  const { currentOrganisationId } = storeToRefs(organisationStore)

  const sectionList: Ref<Array<HubSectionConfig>> = ref([])
  const sectionByOrganisationList: Ref<Array<{
    rootOrgId: number
    sections: Array<HubSectionConfig>
  }>> = ref([])

  const computedSectionList: ComputedRef<Array<HubSectionConfig>> = computed(() => sectionList.value)
  const currentOrganisationSections: ComputedRef<Array<HubSectionConfig>> = computed(() => {
    return sectionByOrganisationList.value
      .find(item => item.rootOrgId === currentOrganisationId.value)?.sections
      || []
  })

  async function fetchSections() {
    if (sectionList.value && sectionList.value.length) {
      return sectionList.value
    }

    const { error } = await useHubFetch<Array<HubSectionConfig>>('api/v4/lookups/dashboards/sections', {
      onResponse: response => {
        sectionList.value = response.response._data.sort(
          (a: HubSectionConfig, b: HubSectionConfig) => a.ordinal - b.ordinal
        )
      }
    })

    if (error.value) {
      throw new Error('Sections Not Loaded')
    }

    return sectionList.value
  }

  async function fetchSectionForOrganisation(rootOrgId: number, secondAttempt: boolean = false) {
    if (sectionByOrganisationList.value.length) {
      const orgSections = sectionByOrganisationList.value.find(item => item.rootOrgId === rootOrgId)
      if (orgSections) {
        return orgSections.sections
      }
    }

    if (secondAttempt) return []

    await useHubFetch<Array<HubSectionConfig>>(`api/v4/organisations/${rootOrgId}/lookups/sections`, {
      onResponse: ({ response }) => {
        sectionByOrganisationList.value.push({
          rootOrgId: rootOrgId,
          sections: response._data.sort(
            (a: HubSectionConfig, b: HubSectionConfig) => a.ordinal - b.ordinal
          )
        })
      }
    })

    return fetchSectionForOrganisation(rootOrgId, true)
  }

  return {
    // internal refs
    sectionList,
    sectionByOrganisationList,

    // public
    computedSectionList,
    currentOrganisationSections,

    fetchSections,
    fetchSectionForOrganisation
  }
})
