<template>
  <div>
    <h2
      v-if="showHeader"
      class="mt-2 flex items-center justify-between py-2"
    >
      <div class="flex w-full cursor-pointer items-center justify-start">
        <HubButton
          btn-style="print:hidden mr-2 bg-trublue rounded-full p-1.5"
          icon-only
          icon-classes="text-white"
          :icon="'i-mdi-' + (isVisible ? 'chevron-down' : 'chevron-up')"
          @click="setIsVisible"
        />
        <h3 class="text-lg font-semibold">
          {{ translateLabel ? $t('dashboards.section.name.' + label) : label }}
        </h3>
      </div>
    </h2>

    <div v-if="smallOrMediumViewport">
      <div class="relative my-5 flex items-center justify-center">
        <h3 class="text-lg font-semibold">
          {{ translateLabel ? $t('dashboards.section.name.' + label) : label }}
        </h3>
        <UIcon
          v-if="canEditDashboard || canCreateDashboard"
          name="i-mdi-cog-outline"
          class="absolute right-4 !size-5 cursor-pointer text-trublue hover:text-blue-3"
          @click="showSettingsDialog = true"
        />
      </div>
      <div v-if="sectionItemDisplayType === 'list'">
        <DashboardSectionItem
          v-for="item in visibleItems"
          :key="`dsi_${touchpointId}_${item.id}`"
          v-bind="item"
          :section-id="id"
          :dashboard-id="dashboardId"
          :section-display-type="sectionItemDisplayType"
          :touchpoint-id="touchpointId"
          :touchpoint-filters="touchpointFilters"
          class="border-b"
        />
      </div>
      <HubTabs
        v-else-if="sectionItemDisplayType === 'tabs'"
        v-model:active-index="visibleComponentIndex"
        :items="visibleItems"
        class="mb-4"
        header-key="label"
        theme="blue"
        @update:active-index="selectItem($event, visibleItems[$event])"
      >
        <template #header="{ header, item }">
          {{ item.translateLabel ? $t('dashboards.section.itemName.' + header) : header }}
        </template>
        <template #item="{ item }">
          <DashboardSectionItem
            v-if="visibleComponentIndex === item.ordinal"
            v-bind="(item as HubSectionItemConfig)"
            :key="`tabs_${touchpointId}_${item.id}`"
            :section-id="id"
            :dashboard-id="dashboardId"
            :section-display-type="sectionItemDisplayType"
            :touchpoint-id="touchpointId"
            :touchpoint-filters="touchpointFilters"
          />
        </template>
      </HubTabs>
    </div>
    <div
      v-else-if="isVisible || forceVisible"
      :id="`${props.touchpointId}:${props.id}`"
      class="w-full rounded-lg border-0 border-mid-grey lg:flex lg:border print:border-0"
    >
      <div
        class="hidden rounded-t-lg border-b bg-blue-8 py-4 md:mb-0
        md:rounded-l-lg md:rounded-tr-none md:border-b-0 md:border-r lg:block print:hidden"
        :class="showNavigation ? ' md:w-1/3 lg:w-1/4 xl:w-1/5 ' : ''"
      >
        <div class="sticky top-32">
          <UIcon
            :name="'i-mdi-' + (showNavigation ? 'menu-open' : 'menu')"
            class="mx-4 !size-6 text-trublue"
            @click="toggleItemNavigation"
          />
          <div
            v-if="showNavigation"
            class="mt-3"
          >
            <div
              v-for="(item, index) in visibleItems"
              :key="index"
              :class="getNavigationItemClasses(index)"
              @click="selectItem(index, item)"
            >
              {{ item.translateLabel ? $t('dashboards.section.itemName.' + item.label) : item.label }}
            </div>

            <template v-if="canEditDashboard || canCreateDashboard">
              <UDivider
                type="dashed"
                :ui="{ wrapper: { base: 'px-6 pt-3' }, border: { base: 'border-mid-grey' } }"
              />
              <UIcon
                name="i-mdi-cog-outline"
                class="mx-6 mr-auto mt-6 !size-5 cursor-pointer text-trublue hover:text-blue-3"
                @click="showSettingsDialog = true"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="w-full overflow-visible overflow-x-scroll print:relative print:overflow-hidden">
        <DashboardSectionItem
          v-if="sectionItemDisplayType === 'tabs'"
          v-bind="currentItem"
          :key="`tabs_${touchpointId}_${currentItem.id}`"
          :section-id="id"
          :dashboard-id="dashboardId"
          :section-display-type="sectionItemDisplayType"
          :touchpoint-id="touchpointId"
          :touchpoint-filters="touchpointFilters"
        />
        <DashboardSectionItem
          v-for="item in visibleItems"
          v-else
          :key="`dsi_${touchpointId}_${item.id}`"
          v-bind="item"
          :section-id="id"
          :dashboard-id="dashboardId"
          :section-display-type="sectionItemDisplayType"
          :touchpoint-id="touchpointId"
          :touchpoint-filters="touchpointFilters"
          class="border-b"
        />
      </div>
    </div>

    <LazyDashboardSectionSettings
      v-model="showSettingsDialog"
      v-bind="props"
      :original-section-items="originalSectionConfiguration?.items"
      @update="($event) => emit('update', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import type { WritableComputedRef } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type { HubDashboardBaseModal, HubSectionConfig, HubSectionItemConfig } from '~/types/configuration'
import type { PatchObject } from '~/types'

interface SectionProps extends HubSectionConfig {
  dashboardId: string
  dashboard: HubDashboardBaseModal
  touchpointId: string
  touchpointFilters?: { [name: string]: string | number | undefined }
  showHeader?: boolean
  forceVisible?: boolean
}

const props = withDefaults(defineProps<SectionProps>(), {
  showHeader: false,
  forceVisible: false,
  touchpointFilters: undefined
})

const emit = defineEmits<{
  (e: 'update', arg1: Array<PatchObject>): void
}>()

// store refs
const filterStore = useFilterStore()
const sectionStore = useSectionStore()
const { params } = storeToRefs(filterStore)
const { currentOrganisationSections } = storeToRefs(sectionStore)

// local refs
const showNavigation: Ref<boolean> = ref(true)
const showSettingsDialog: Ref<boolean> = ref(false)
const visibleComponentIndex: Ref<number> = ref(0)

const breakpoints = useBreakpoints(breakpointsTailwind)
const activeBreakpoints = breakpoints.active()

// computed props
const canEditDashboard: ComputedRef<boolean> = computed(() => {
  return checkPermissions({
    permissions: props.dashboard.isOwner
      ? 'Dashboards.Default.Self.Update'
      : 'Dashboards.Default.Organisation.Update'
  })
})
const canCreateDashboard: ComputedRef<boolean> = computed(() => {
  return checkPermissions({
    permissions: 'Dashboards.Default.Self.Create'
  })
})

const originalSectionConfiguration = computed(() => currentOrganisationSections.value.find(s => s.id === props.id))
const visibleItems: ComputedRef<Array<HubSectionItemConfig>> = computed(() =>
  props.items.filter(i => i.isEnabled).sort((a, b) => a.ordinal - b.ordinal)
)
const currentItem: ComputedRef<HubSectionItemConfig> = computed(() => visibleItems.value[visibleComponentIndex.value])
const sectionItemDisplayType: WritableComputedRef<'tabs' | 'list'> = computed({
  get() {
    return (params.value[`${props.touchpointId}:${props.id}-displayType`] as 'tabs' | 'list') || props.displayType
  },
  set(value) {
    params.value[`${props.touchpointId}:${props.id}-displayType`] = value
  }
})
const smallOrMediumViewport = computed(() =>
  !activeBreakpoints.value || ['xs', 'sm', 'md'].includes(activeBreakpoints.value)
)

// functions
function getNavigationItemClasses(itemIndex: string | number) {
  let classes = ' flex items-center px-2 py-2 mx-4 text-sm truncate rounded-lg cursor-pointer'

  if (sectionItemDisplayType.value === 'tabs') {
    classes += ' text-grey-blue'

    if (visibleComponentIndex.value === itemIndex) {
      return classes + ' bg-trublue text-white hover:bg-blue-3'
    }

    return classes + ' hover:bg-blue-7'
  }

  return classes + ' hover:bg-blue-7 text-trublue'
}
function toggleItemNavigation() {
  showNavigation.value = !showNavigation.value
}
function selectItem(index: number, item: HubSectionItemConfig) {
  visibleComponentIndex.value = index
  params.value.hash = props.touchpointId + ':' + props.id + ':' + item.id

  if (item.isEnabled || sectionItemDisplayType.value === 'tabs') {
    scrollToElement(props.touchpointId + ':' + props.id + ':' + item.id, 140)
  }
}
function setIsVisible() {
  emit('update', [{
    value: !props.isVisible,
    path: 'isVisible',
    op: 'replace'
  }])
}

function scrollToHashElement() {
  // if there's no hash, return
  if (!params.value.hash) {
    return
  }

  const [touchpointId, sectionId, sectionItemId] = params.value.hash.split(':')

  if (touchpointId !== props.touchpointId && sectionId !== props.id && props.isVisible === true) {
    return
  }

  // In theory the section should match, but just in case the touchpoint doesn't have the same section we'll check
  if (touchpointId === props.touchpointId && sectionId === props.id) {
    if (props.isVisible === false) {
      emit('update', [{
        value: true,
        path: 'isVisible',
        op: 'replace'
      }])
    }

    if (sectionItemId) {
      const index = visibleItems.value.findIndex(i => i.id === sectionItemId)
      if (index !== -1) {
        visibleComponentIndex.value = index
      }
    }

    const scrollToSectionItem = (tryAgain: boolean = false, timeout: number = 1000) => {
      setTimeout(() => {
        if (!params.value.hash) {
          // handling this in the parent but for some reason it complains in here, so just return
          return
        }

        const el = document.getElementById(params.value.hash)
        if (el) {
          const rect = el.getBoundingClientRect()
          const elemTop = rect.top
          const elemBottom = rect.bottom

          // If section item is already displayed, don't scroll
          // Only completely visible elements return true:
          if ((elemTop >= 0) && (elemBottom <= window.innerHeight)) {
            return
          }

          scrollToElement(params.value.hash, 140)
        }

        if (!el && tryAgain) {
          scrollToSectionItem(false, timeout * 2) // try again in 2 seconds
        }
      }, timeout)
    }

    scrollToSectionItem(true)
  }
}

watch(
  () => props.forceVisible,
  () => {
    if (props.forceVisible) {
      params.value.hash = props.touchpointId + ':' + props.id
    }
  }
)

watch(
  [() => params.value.hash, () => sectionItemDisplayType.value],
  () => {
    scrollToHashElement()
  },
  {
    immediate: true
  }
)
</script>
