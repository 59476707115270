<template>
  <div
    :id="touchpoint.id"
    :class="touchpoint.ordinal > 0 ? 'print:break-inside-avoid-page' : ''"
  >
    <DashboardTouchpointBanner
      v-bind="touchpoint"
      v-model:visibility-toggle="visibilityToggle"
      v-model:show-settings-dialog="showSettingsDialog"
      :dashboard-id="dashboard.id"
      :dashboard="dashboard"
      @update="($event) => $emit('update', $event)"
    />
    <div
      v-if="touchpoint.isVisible || dashboard.config.touchpointDisplayType == 'tabs'"
      :id="touchpoint.id + '-sections'"
      class="my-4"
    >
      <HubTabs
        v-if="touchpoint.displayType == 'tabs'"
        :active-index="activeSectionIndex"
        :items="enabledSections"
        class="mb-4"
        header-key="label"
        theme="blue"
        lazy
        @update:active-index="(index: number) => selectSection(enabledSections[index].id)"
      >
        <template #header="{ header, item }">
          {{ item.translateLabel ? $t('dashboards.section.name.' + header) : header }}
        </template>
        <template #item="{ item }">
          <DashboardSection
            v-bind="(item as HubSectionConfig)"
            :dashboard-id="dashboard.id"
            :dashboard="dashboard"
            :touchpoint-id="touchpoint.id"
            :touchpoint-filters="touchpoint.queryParams"
            force-visible
            @update="($event) => updateSection(item.id, $event)"
          />
        </template>
      </HubTabs>

      <DashboardSection
        v-for="section in enabledSections"
        v-else
        :key="section.id"
        class="mx-4"
        v-bind="section"
        :dashboard-id="dashboard.id"
        :dashboard="dashboard"
        :touchpoint-id="touchpoint.id"
        :touchpoint-filters="touchpoint.queryParams"
        show-header
        @update="($event) => updateSection(section.id, $event)"
      />
    </div>

    <LazyDashboardTouchpointSettings
      v-model="showSettingsDialog"
      v-bind="(props.touchpoint as HubTouchpointConfig)"
      :sections="sections"
      :section-display-type="touchpoint.displayType"
      @update="($event) => $emit('update', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import type { PartialExceptTheseRequired, PatchObject } from '~/types'
import type {
  HubDashboardBaseModal,
  HubSectionConfig,
  HubTouchpointConfig,
  PartialHubSectionConfig,
  HubSectionItemConfig
} from '~/types/configuration'

const props = defineProps<{
  touchpoint: HubTouchpointConfig
  dashboard: HubDashboardBaseModal
}>()

const emit = defineEmits<{
  (e: 'update', arg1: Array<PatchObject>): void
}>()

// store refs
const sectionStore = useSectionStore()
const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)
const { currentOrganisationSections } = storeToRefs(sectionStore)

const visibilityToggle = computed(() => {
  if (props.dashboard.config.touchpointDisplayType === 'tabs') {
    return false
  }

  const selectedTouchpointIds = params.value.touchpointIds
  if ((selectedTouchpointIds && !Array.isArray(selectedTouchpointIds))
    || (Array.isArray(selectedTouchpointIds) && selectedTouchpointIds.length === 1)) {
    return false
  }

  return true
})

// internal refs
const showSettingsDialog = ref(false)
const activeSectionIndex = ref()

// computed refs
const sections: ComputedRef<Array<HubSectionConfig>> = computed(() => {
  const sections: Array<HubSectionConfig> = []

  for (const section of currentOrganisationSections.value) {
    if (!props.dashboard.config.sections) {
      // if there are no sections defined in the dashboard config, use the default sections
      sections.push(section)
      continue
    }

    const sectionOverride: PartialHubSectionConfig | undefined = getSectionOverride(section.id)

    if (!sectionOverride || objectIsEmpty(sectionOverride)) {
      sections.push(section)
      continue
    }

    const customisedSection: HubSectionConfig = {
      ...section,
      ...sectionOverride,
      items: section.items
    }

    if (sectionOverride.items && sectionOverride.items.length) {
      customisedSection.items = []
      for (const item of section.items) {
        const sectionItemOverride = sectionOverride.items.find(i => i.id === item.id)

        if (!sectionItemOverride) {
          customisedSection.items.push(item)
          continue
        }

        customisedSection.items.push({ ...item, ...sectionItemOverride })
      }
    }

    sections.push(customisedSection)
  }

  return sections.sort((a, b) => a.ordinal - b.ordinal)
})
const enabledSections: ComputedRef<Array<HubSectionConfig>> = computed(() => sections.value.filter(s => s.isEnabled))

// functions
function getSectionOverride(sectionId: string): PartialHubSectionConfig | undefined {
  const dashboardSectionOverride = props.dashboard.config.sections.find(s => s.id === sectionId)
  const touchpointSectionOverride = props.touchpoint.sections.find(s => s.id === sectionId)

  if (!dashboardSectionOverride && !touchpointSectionOverride) {
    return undefined
  }

  if (dashboardSectionOverride && touchpointSectionOverride) {
    const sectionOverride = {
      ...touchpointSectionOverride,
      ...dashboardSectionOverride
    }

    const sectionOverrideItems: Array<PartialExceptTheseRequired<HubSectionItemConfig, 'id'>> = []

    if (touchpointSectionOverride.items && touchpointSectionOverride.items.length) {
      sectionOverrideItems.push(...touchpointSectionOverride.items)
    }

    if (dashboardSectionOverride.items && dashboardSectionOverride.items.length) {
      if (sectionOverrideItems.some(i => dashboardSectionOverride.items?.some(di => i.id === di.id))) {
        for (const item of sectionOverrideItems) {
          const itemOverride = dashboardSectionOverride.items.find(i => i.id === item.id)
          if (!itemOverride) {
            continue
          }

          // do magic to work out if we care
        }
      } else {
        sectionOverrideItems.push(...dashboardSectionOverride.items)
      }
    }

    return {
      ...sectionOverride,
      items: sectionOverrideItems
    }
  }

  if (dashboardSectionOverride) {
    return dashboardSectionOverride
  }

  return touchpointSectionOverride
}
function selectSection(sectionId: string): void {
  params.value.hash = props.touchpoint.id + ':' + sectionId
}
function openHashedSection(): void {
  if (!params.value.hash) {
    return
  }

  const [touchpointId, sectionId] = params.value.hash.split(':')
  if (touchpointId === props.touchpoint.id) {
    const sectionIndex = enabledSections.value.findIndex(s => s.id === sectionId)
    if (sectionIndex === -1) {
      return
    }
    activeSectionIndex.value = sectionIndex
  }
}
function updateSection(sectionId: string, sectionUpdateArray: Array<PatchObject>) {
  const squishSection = (sectionUpdates: Array<PatchObject>): { [name: string]: unknown } => {
    const sectionUpdateObject = sectionUpdates.reduce((acc, su) => {
      acc[su.path] = su.value
      return acc
    }, {} as Record<string, unknown>)

    return sectionUpdateObject
  }

  if (props.touchpoint.sections) {
    const sectionIndex = props.touchpoint.sections.findIndex(s => s.id === sectionId)
    if (sectionIndex === -1) {
      // if there's not an existing section config, add a new one
      const squishedSection = squishSection(sectionUpdateArray)
      emit('update', [{
        op: 'add',
        path: 'sections',
        value: [
          ...props.touchpoint.sections,
          {
            id: sectionId,
            ...squishedSection
          }
        ]
      }])
      return
    }
    const remappedSectionUpdateArray = sectionUpdateArray.map(su => ({
      ...su,
      path: `sections/${sectionIndex}/${su.path}`
    }))
    emit('update', remappedSectionUpdateArray)
    return
  }

  const squishedSection = squishSection(sectionUpdateArray)
  emit('update', [{
    op: 'add',
    path: 'sections',
    value: [{
      id: sectionId,
      ...squishedSection
    }]
  }])
}

// watchers
watch(
  [
    () => params.value.hash,
    () => enabledSections.value
  ],
  () => {
    openHashedSection()
  },
  {
    immediate: true
  }
)
</script>
