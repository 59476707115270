import dayjs from 'dayjs'
import groupBy from 'lodash/groupBy'
import { formatColumnData } from './flattenData'
import { getNumberValue, getStringValue } from '~/utils/general-helpers'
import { truMetricsArray, CustomError } from '~/types/app'
import type {
  HubDashboardComponentType,
  HubStatistic,
  HubStatisticDataItem,
  HubTable,
  HubTableColumn,
  ServerColumnRow,
  ServerDataResponse,
  ServerDataRow
} from '~/types/component'
import type { HubLeaderboardOutlet } from '~/types/report'

interface StatisticListReportConfig {
  groupBy?: string
  value: string
  valueLabel?: string
  maxValue: string | number
  delta?: string
  header?: string | Function
  headerIcon?: string
  showValue?: boolean
  showDelta?: boolean
  getColor?: Function
  footer?: Function | string
  totalResponseCount?: string
  dataDisplayLocation?: 'top' | 'bottom'
}

interface ChartReportConfig {
  yColumn?: string
  xColumn?: string
  groupBy?: string | Function
  getColumn?: Function
  seriesName?: string
  colorName?: string
}

interface PieChartReportConfig {
  labelColumn: string
  dataColumn: string
  showTotal?: boolean
}

interface ParsedData {
  [name: string]: string | number
}

function formatForStatisticList(
  data: Array<ParsedData>,
  config: StatisticListReportConfig
): { data: Array<HubStatistic> } {
  const arrayOfFormattedItems: Array<HubStatistic> = []
  const getHeader = (item: any): string | undefined => {
    if (!config.header) {
      return undefined
    }
    return typeof config.header === 'string' ? getStringValue(item[config.header]) : config.header(item)
  }
  const getFooter = (item: any): string | undefined => {
    if (!config.footer) {
      return undefined
    }
    return typeof config.footer === 'string' ? getStringValue(item[config.footer]) : config.footer(item)
  }
  const getTotalResponse = (item: any): number | undefined => {
    if (config.totalResponseCount) {
      return getNumberValue(item[config.totalResponseCount])
    }

    if (config.maxValue) {
      return getNumberValue(item[config.maxValue])
    }

    return undefined
  }

  const formatItem = (
    item: { [name: string]: string | number },
    configOverride: {
      includeValueLabel: boolean
      showValue?: boolean
      maxValue?: number
      valueColumn?: string
      showDelta?: boolean
    } = {
      includeValueLabel: true,
      showValue: config.showValue
    }
  ): HubStatisticDataItem => {
    let maxValue: number

    if (typeof config.maxValue === 'string') {
      maxValue = getNumberValue(item[config.maxValue])
    } else {
      maxValue = config.maxValue
    }

    if (configOverride.maxValue) {
      maxValue = configOverride.maxValue
    }

    return {
      value: configOverride.valueColumn
        ? getNumberValue(item[configOverride.valueColumn])
        : getNumberValue(item[config.value]),
      valueLabel:
        config.valueLabel && configOverride.includeValueLabel ? getStringValue(item[config.valueLabel]) : undefined,
      showDelta: config.showDelta && configOverride.showDelta,
      showValue: typeof configOverride.showValue === 'boolean' ? configOverride.showValue : config.showValue,
      maxValue,
      delta: config.showDelta && config.delta ? getNumberValue(item[config.delta]) : undefined,
      type: 'decimal'
    }
  }

  if (config.groupBy) {
    const groupedData: {
      [index: string]: Array<{ [name: string]: string | number }>
    } = groupBy(data, config.groupBy)

    for (const [index, item] of Object.entries(groupedData)) {
      arrayOfFormattedItems.push({
        id: index,
        dataDisplayLocation: config.dataDisplayLocation || 'top',
        header: getHeader(item[0]),
        footer: getFooter(item[0]),
        color: config.getColor ? config.getColor(item[0]) : undefined,
        totalResponseCount: getTotalResponse(item[0]),
        data: item.map(x => {
          const itemIsAverage = !!(config.valueLabel && ['Average', 'Moyenne'].includes(x[config.valueLabel] as string))

          return formatItem(x, {
            includeValueLabel: !itemIsAverage,
            showValue: itemIsAverage,
            maxValue: itemIsAverage ? 9 : undefined,
            valueColumn: itemIsAverage ? '[Value]' : undefined
          })
        })
      })
    }

    return { data: arrayOfFormattedItems }
  }

  for (const [index, item] of data.entries()) {
    const formattedItem = formatItem(item)

    arrayOfFormattedItems.push({
      id: index.toString(),
      dataDisplayLocation: config.dataDisplayLocation || 'top',
      header: getHeader(item),
      color: config.getColor ? config.getColor(item) : undefined,
      totalResponseCount: getTotalResponse(item),
      data: [
        {
          ...formattedItem
        }
      ]
    })
  }

  return { data: arrayOfFormattedItems }
}

function formatForChart(data: Array<ParsedData>, config: ChartReportConfig) {
  const arrayOfFormattedItems = []
  const groupedData: {
    [index: string]: Array<{ [name: string]: string | number }>
  } = groupBy(data, config.groupBy)
  const entries = config.groupBy ? Object.entries(groupedData) : data.entries()
  const getColumn = (i: { [name: string]: string | number }) => {
    if (config.getColumn) {
      return config.getColumn(i)
    }
    return [config.xColumn ? i[config.xColumn] : undefined, config.yColumn ? i[config.yColumn] : undefined]
  }

  for (const [index, item] of entries) {
    arrayOfFormattedItems.push({
      name: config.seriesName || index,
      data: Array.isArray(item) ? item.map(i => getColumn(i)) : getColumn(item),
      colorName: config.colorName
    })
  }

  return arrayOfFormattedItems
}

function formatForPieChart(data: Array<ParsedData>, config: PieChartReportConfig) {
  const filteredData = data.filter(row => row['[Response Share]'] !== '100%')

  const series = filteredData.map(row => getNumberValue(row[config.dataColumn]))
  const labels = filteredData.map(row => row[config.labelColumn])

  return {
    series,
    labels,
    showTotal: config.showTotal
  }
}

function formatPerformanceSummaryMeasures(
  data: Array<Array<ServerDataRow>>,
  columns: Array<ServerColumnRow>,
  displayType: HubDashboardComponentType = 'list',
  includeMetrics: boolean = true,
  includePromoter: boolean = true
): Array<
  | HubStatistic
  | { name: string, data: Array<number> }
  | {
    name: string
    type: string | null
    data: { [name: string]: string | number }
  }
  > {
  interface FlattenedPerformanceKeyMetric {
    name: string
    formattedName: string
    type: string | null
    data: { [name: string]: string | number }
  }

  const formattedArray: Array<HubStatistic | FlattenedPerformanceKeyMetric> = []
  const flattenedData: Array<Array<string | number>> = data.map(row => row.map(x => {
    if (x.type === 'string') {
      return x.value
    }
    if (x.type === 'decimal' || x.type === 'integer') {
      // This should be handled by the API in the future, but for now we need to handle it here
      return getNumberValue(x.rawValue as string | number)
    }

    return x.value
  })) as Array<Array<string | number>>

  if (!flattenedData.length || !flattenedData[0] || !flattenedData[0].map(x => getNumberValue(x)).some(x => x > 0)) {
    throw new CustomError(
      'reports.errors.generic.header.lowData',
      'reports.errors.generic.message.selectDifferentFilters'
    )
  }

  if (displayType === 'barChart') {
    const barChartColumns = columns.map(col => ({
      referenceName: col.dimensionCaption,
      name: col.formattedCaption || col.dimensionCaption,
      data: []
    }))

    const calculationIndex = barChartColumns.findIndex(col => col.referenceName.toLowerCase().includes('calculation'))

    for (const row of flattenedData) {
      const columnName: string = getStringValue(row[calculationIndex])

      for (const [childIndex, childRow] of row.entries()) {
        if (columnName === 'Real Value') {
          const childValue = getNumberValue(childRow)
          if (childValue >= 0) {
            // @ts-expect-error child values should be set
            barChartColumns[childIndex].data = [childValue]
          }
        }
      }
    }

    barChartColumns.splice(calculationIndex, 1)

    return barChartColumns.filter(
      c =>
        (truMetricsArray.find(metric => metric.name === c.referenceName.toLowerCase())
          || c.name.toLowerCase() === 'trurating')
          && c.data.length
    )
  }

  const flattenedColumns: Array<FlattenedPerformanceKeyMetric> = columns.map(col => ({
    name: col.dimensionCaption.replace(/[[\]]/g, ''),
    formattedName: col.formattedCaption || col.dimensionCaption,
    type: col.columnDataType,
    data: {}
  }))

  for (const row of flattenedData) {
    const calculationIndex = flattenedColumns.findIndex(col => col.name.toLowerCase().includes('calculation'))
    const columnName: string = getStringValue(row[calculationIndex])
    for (const [childIndex, childRow] of row.entries()) {
      flattenedColumns[childIndex].data[columnName] = formatColumnData(childRow, flattenedColumns[childIndex].type)
    }
  }

  const formatItemForStatisticList = (
    item: FlattenedPerformanceKeyMetric,
    showDelta: boolean = false,
    dataDisplayLocation: 'top' | 'bottom' = 'top',
    maxValue: number = 9,
    minValue?: number,
    color?: string,
    icon?: string,
    headerImage?: string
  ): HubStatistic => ({
    id: item.name,
    dataDisplayLocation,
    header: item.formattedName,
    headerIcon: icon,
    headerImage,
    color,
    totalResponseCount: getNumberValue(item.data.RatingQuantity),
    showTooltip: item.name.toLowerCase() !== 'trurating',
    tooltip: {
      type: 'question',
      questions: []
    },
    data: [
      {
        value: getNumberValue(item.data['Real Value']),
        maxValue,
        minValue,
        showDelta,
        showValue: true,
        delta: getNumberValue(item.data.Delta)
      }
    ]
  })

  const formatItem = (
    displayType: HubDashboardComponentType = 'list',
    config: {
      item: FlattenedPerformanceKeyMetric
      showDelta: boolean
      dataDisplayLocation: 'top' | 'bottom'
      maxValue: number
      minValue?: number
      color?: string
      icon?: string
      headerImage?: string
    }
  ) =>
    displayType === 'promoter'
      ? config.item
      : formatItemForStatisticList(
        config.item,
        config.showDelta,
        config.dataDisplayLocation,
        config.maxValue,
        config.minValue,
        config.color,
        config.icon,
        config.headerImage
      )

  flattenedColumns.forEach(x => {
    const columnName: string = x.name.toLowerCase()

    if (
      ((getNumberValue(x.data['Real Value']) < 0 && columnName !== 'trupromoter'))
      || columnName === 'calculation') {
      // ignore values below 0, assume that we don't want them, unless it's tru promoter
      // or calculation
      return
    }

    const truMetric = truMetricsArray.find(
      metric => metric.name === columnName || metric.displayName.toLowerCase() === columnName
    )

    if (includeMetrics && truMetric) {
      formattedArray.push(
        formatItem(displayType, {
          item: x,
          showDelta: true,
          dataDisplayLocation: 'top',
          maxValue: 9,
          color: truMetric?.color || truMetric.name,
          icon: truMetric?.icon,
          headerImage: truMetric?.img
        })
      )
    }

    if (includePromoter && !truMetric) {
      formattedArray.push(
        formatItem(displayType, {
          item: x,
          showDelta: columnName === 'tru promoter',
          dataDisplayLocation: 'top',
          maxValue: 100,
          minValue: columnName === 'tru promoter' ? -100 : 0,
          headerImage: columnName === 'tru promoter' ? 'tru-logo-button-dark.svg' : undefined
        })
      )
    }
  })

  return formattedArray
}

function parseData(
  columns: Array<ServerColumnRow>,
  data: Array<Array<ServerDataRow>>,
  fakeReport: boolean = false,
  flattenDataMethod?: Function
): Array<{ [name: string]: any }> {
  if (fakeReport) {
    const fakedReportDataArray = []
    for (const row of data) {
      // @ts-expect-error - fake report type
      const parsedRowData = flattenData(columns, row.rows, undefined, flattenDataMethod)
      // @ts-expect-error - fake report type
      fakedReportDataArray.push({ rows: parsedRowData, metric: row.metric })
    }

    return fakedReportDataArray
  }

  return flattenData(columns, data, undefined, flattenDataMethod)
}

export default function (
  reportData: ServerDataResponse | undefined | null,
  displayType: HubDashboardComponentType | undefined,
  fakeReport: boolean = false
) {
  if (!reportData) {
    return reportData
  }

  const columns: Array<ServerColumnRow> = reportData.results.columnHeadings
  const data: Array<Array<ServerDataRow>> = reportData.results.rows
  const reportMeta = reportData._metaData

  // array of objects
  let parsedData: Array<{ [name: string]: any }> = parseData(columns, data, fakeReport)

  if (reportMeta.reportId === 'PerformanceSummaryKeyMeasures') {
    if (displayType === 'promoter') {
      const promoterData = formatPerformanceSummaryMeasures(data, columns, displayType, false, true)
      return { data: promoterData }
    }

    if (displayType === 'list') {
      const includeMetrics = ['metric', 'all', null].includes(reportData.rawApiParameters.measureFamily as string)
      const includePromoter = ['measure', 'all', null].includes(reportData.rawApiParameters.measureFamily as string)

      try {
        const keyMeasuresData = formatPerformanceSummaryMeasures(data, columns, displayType, includeMetrics, includePromoter)
        return {
          data: keyMeasuresData
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        throw new CustomError(e.title, e.statusMessage)
      }
    }

    return {
      series: formatPerformanceSummaryMeasures(data, columns, displayType),
      labels: ['reports.metrics']
    }
  } else if (reportMeta.reportId === 'CoreQuestionBenchmarkingPeerToPeer') {
    interface HubLeaderboardOutletWithAllColumns extends HubLeaderboardOutlet {
      isSelectedOrganisation: boolean
      totalOrganisationQuantity: number
    }

    const formattedData: Array<HubLeaderboardOutletWithAllColumns> = parsedData.map(row => ({
      rank: row['[Rank]'],
      displayName: row['[Organisation]'],
      value: row['[Score]'],
      outOfAmount: 9,
      isSelectedOrganisation: !!row['[IsSelectedOrganisation]'],
      totalOrganisationQuantity: row['[BenchmarkOrganisationQuantity]']
    }))

    if (formattedData.length <= 1) {
      throw new CustomError('reports.errors.leaderboard.header', 'reports.errors.leaderboard.message')
    }

    const uniqueRowsByDisplayName = formattedData.filter(
      (item, index, self) => self.findIndex(t => t.displayName === item.displayName) === index
    )
    const totalOutletCount
      = formattedData[0].totalOrganisationQuantity > 4
        ? formattedData[0].totalOrganisationQuantity
        : uniqueRowsByDisplayName.length

    const currentOutlet: HubLeaderboardOutlet = formattedData.find(
      outlet => outlet.isSelectedOrganisation
    ) as HubLeaderboardOutlet
    const rankedOutlets: Array<HubLeaderboardOutlet>
      = formattedData.length > 3 ? formattedData.filter(outlet => !outlet.isSelectedOrganisation) : formattedData

    return {
      currentOutlet,
      rankedOutlets,
      totalOutletCount
    }
  } else if (reportMeta.reportId === 'ResponseRateBanner') {
    if (displayType === 'pieChart') {
      return {
        series: [
          getNumberValue(parsedData[0]['[Timeout Quantity]']),
          getNumberValue(parsedData[0]['[Skip Quantity]']),
          getNumberValue(parsedData[0]['[Response Quantity]'])
        ],
        labels: [
          'futureBackendTranslations.touchpointBanner.Timeout',
          'futureBackendTranslations.touchpointBanner.Skip Rate',
          'futureBackendTranslations.touchpointBanner.Response Quantity'
        ],
        translateLabels: true,
        showTotal: true,
        theme: 'sentiment'
      }
    }

    const totalResponseCount = getNumberValue(parsedData[0]['[Questions Asked]'])

    return {
      id: 'questions_asked',
      header: 'Questions Asked',
      totalResponseCount,
      data: [
        {
          valueLabel: 'Response Rate',
          value: getNumberValue(parsedData[0]['[Response Quantity]']),
          maxValue: totalResponseCount,
          showValue: false
        },
        {
          valueLabel: 'Skip Rate',
          value: getNumberValue(parsedData[0]['[Skip Quantity]']),
          maxValue: totalResponseCount,
          showValue: false
        },
        {
          valueLabel: 'Timeout',
          value: getNumberValue(parsedData[0]['[Timeout Quantity]']),
          maxValue: totalResponseCount,
          showValue: false
        }
      ]
    }
  } else if (reportMeta.reportId === 'ResponseRateOverTime') {
    const groupedData = groupBy(parsedData, '[MetricName]')
    const filteredGroupedData = Object.entries(groupedData).filter(([index]) => index.toLowerCase() === 'overall')

    const getColumn = (i: { [name: string]: string | number }) => {
      return [i['[Start Date]'], getNumberValue(i['[ResponseRate]'])]
    }

    const formattedData = []
    for (const [index, item] of filteredGroupedData) {
      formattedData.push({
        name: index,
        data: Array.isArray(item) ? item.map(i => getColumn(i)) : getColumn(item)
      })
    }

    return {
      series: formattedData
    }
  } else if (reportMeta.reportId === 'ResponseShareOverTime') {
    const customParsedData = parseData(columns, data, fakeReport, (row: ServerDataRow) => {
      if (['string'].includes(row.type)) {
        return row.formattedValue
      }

      if (row.type === 'decimal') {
        return row.rawValue
      }

      return row.value
    })

    const getColumn = (i: { [name: string]: string | number }) => {
      const xColumn = dayjs(i['[StartDate]']).format('YYYY-MM-DD')
      const yColumn = getNumberValue(i['[Rate]']).toFixed(2)
      return [xColumn, parseFloat(yColumn)]
    }

    const formattedChart = formatForChart(customParsedData, {
      getColumn,
      groupBy: '[ResponseType]'
    })

    return {
      labels: formattedChart.map(item => item.name),
      series: formattedChart,
      theme: 'mono',
      translateLabels: false,
      options: {
        chart: {
          stacked: true,
          stackType: '100%'
        }
      },
      dataFormatter: (val: string) => {
        return val + '%'
      }
    }
  } else if (reportMeta.reportId.toLowerCase().includes('atv')) {
    parsedData = parseData(columns, data, fakeReport)

    let valueColumn = '[Atv]'
    let labelColumn = '[SegmentLabel]'
    let labels: Array<string> = ['']

    if (reportMeta.reportId === 'CustomQuestionBinaryAtv') {
      valueColumn = '[ATV]'
      labelColumn = '[OptionDescription]'
    }

    if (fakeReport) {
      labels = parsedData.map(row => (row.metric ? row.metric.displayName : undefined))
      parsedData = parsedData.map(row => row.rows).flat()
    }

    const currencySymbol = parsedData[0]['[CurrencySymbol]']

    if (!currencySymbol) {
      throw new CustomError('reports.errors.atv.header', 'reports.errors.atv.message')
    }

    return {
      series: formatForChart(parsedData, {
        groupBy: labelColumn,
        getColumn: (i: { [name: string]: string | number }) => {
          return getNumberValue(i[valueColumn]).toFixed(2)
        }
      }),
      labels,
      theme: reportMeta.reportId.includes('CustomQuestion') ? 'mono' : 'sentiment',
      smallColumnWidth: !fakeReport,
      showDataLabels: !fakeReport,
      minValue: 0,
      translateLabels: !fakeReport,
      displayAsCurrency: true,
      currencySymbol
    }
  } else if (reportMeta.reportId === 'CustomQuestionBinaryTrendOverTime') {
    const getColumn = (i: { [name: string]: string | number }) => {
      const xColumn = dayjs(i['[Date]']).format('YYYY-MM-DD')
      let yColumn = 0
      if (i['[Rating Quantity]'] && i['[Total Question Rating Quantity]']) {
        yColumn
          = (getNumberValue(i['[Rating Quantity]']) / getNumberValue(i['[Total Question Rating Quantity]'])) * 100

        yColumn = parseFloat(yColumn.toFixed(2))
      }

      return [xColumn, yColumn]
    }

    const formattedData = formatForChart(parsedData, {
      getColumn,
      groupBy: '[Binary Option]'
    })
    const dataFormatter = (val: string) => {
      return val + '%'
    }

    return {
      series: formattedData,
      theme: 'mono',
      options: {
        dataLabels: {
          enabled: displayType === 'barChart',
          formatter: dataFormatter
        },
        yaxis: {
          labels: {
            show: true,
            formatter: dataFormatter
          }
        }
      }
    }
  } else if (reportMeta.reportId === 'CustomQuestionScaledTrendOverTime') {
    const formattedData = formatForChart(parsedData, {
      getColumn: (i: { [name: string]: string | number }) => {
        return [dayjs(i['[Date]']).format('YYYY-MM-DD'), getNumberValue(i['[Value]']).toFixed(2)]
      },
      groupBy: '[Binary Option]'
    })

    return {
      series: formattedData,
      theme: 'mono'
    }
  } else if (reportMeta.reportId === 'CustomMetricMCTrendOverTime') {
    const formattedData = formatForChart(parsedData, {
      getColumn: (i: { [name: string]: string | number }) => {
        return [dayjs(i['[StartDate]']).format('YYYY-MM-DD'), getNumberValue(i['[Score]']).toFixed(2)]
      },
      groupBy: '[LabelName]'
    })

    return {
      series: formattedData
    }
  } else if (['CoreQuestionResponseShare', 'CustomQuestionScaledResponseShare'].includes(reportMeta.reportId)) {
    parsedData = parseData(columns, data, fakeReport, (row: ServerDataRow) => ({ ...row }))

    const theme = reportMeta.reportId === 'CustomQuestionScaledResponseShare' ? 'mono' : 'sentiment'
    if (displayType === 'barChart') {
      let labels: Array<string> = ['']
      if (fakeReport) {
        labels = parsedData.map(row => (row.metric ? row.metric.displayName : undefined))
        parsedData = parsedData.map(row => row.rows).flat()
      }

      const filteredData = parsedData
        .filter(f => f['[Segment Label]'].value !== 'Total Responses')
        .map(row => {
          Object.entries(row).forEach(([index, item]) => {
            row[index] = item.formattedValue
          })

          return row
        })

      return {
        labels,
        series: formatForChart(filteredData, {
          groupBy: '[Segment Label]',
          getColumn: (i: { [name: string]: string | number }) => getNumberValue(i['[Response Share]'])
        }),
        theme,
        showDataLabels: !fakeReport,
        dataLabelsTop: !fakeReport,
        translateLabels: false,
        options: {
          chart: {
            stacked: fakeReport,
            stackType: '100%'
          }
        },
        dataFormatter: (val: string) => {
          return val + '%'
        }
      }
    }

    const remappedParsedData = parsedData.map(row => {
      Object.entries(row).forEach(([index, item]) => {
        row[index] = item.formattedValue
      })

      return row
    })

    const formattedForPieChart = formatForPieChart(remappedParsedData, {
      dataColumn: '[Rating Quantity]',
      labelColumn: '[Segment Label]',
      showTotal: true
    })

    return {
      ...formattedForPieChart,
      theme
    }
  } else if (reportMeta.reportId === 'CustomQuestionBinaryResponseShare') {
    const pieData = formatForPieChart(parsedData, {
      dataColumn: '[Rating Quantity]',
      labelColumn: '[Response Option Label]',
      showTotal: true
    })
    return {
      ...pieData,
      theme: 'mono'
    }
  } else if (reportMeta.reportId === 'CustomMetricMCScore') {
    return {
      id: reportMeta.reportId + parsedData[0]['[CustomMetricName]'],
      dataDisplayLocation: 'bottom',
      header: parsedData[0]['[CustomMetricName]'],
      headerIcon: undefined,
      color: 'trublue',
      totalResponseCount: getNumberValue(parsedData[0]['[CustomMetricTotalRatingQuantity]']),
      showTooltip: false,
      data: [
        {
          value: getNumberValue(parsedData[0]['[CustomMetricScore]']),
          maxValue: 100,
          minValue: 0,
          showDelta: false,
          showValue: false,
          delta: undefined
        }
      ]
    }
  } else if (['CustomQuestionSummaryScores', 'CustomMetricMCSummaryScores'].includes(reportMeta.reportId)) {
    return formatForStatisticList(parsedData, {
      dataDisplayLocation: 'bottom',
      groupBy: '[QuestionID]',
      value: '[Rating Quantity]',
      valueLabel: '[ValueLabel]',
      maxValue: '[Total Question Rating Quantity]',
      header: '[Question]',
      footer: (i: { [name: string]: string | number }) => {
        return getStringValue(i['[ResponsesFrom]']) + ' - ' + getStringValue(i['[ResponsesTo]'])
      }
    })
  } else if (['PowerUserTrendOverTime', 'TrendOverTime'].includes(reportMeta.reportId)) {
    if (displayType && displayType === 'lineChart') {
      let seriesName: string | undefined = undefined
      let colorName: string | undefined = undefined
      if (reportMeta.reportId == 'TrendOverTime') {
        seriesName = `filters.truMetrics.${reportData.rawApiParameters.truMetric}`
        colorName = reportData.rawApiParameters.truMetric as string
      }

      const formattedData = formatForChart(parsedData, {
        getColumn: (i: { [name: string]: string | number }) => {
          return [i['[Start Date]'], getNumberValue(i['[Metric]'])]
        },
        groupBy: '[MetricName]',
        seriesName,
        colorName
      })

      return {
        series: formattedData,
        translateSeriesNames: reportMeta.reportId === 'TrendOverTime'
      }
    }

    const formattedData = []
    const groupedData = groupBy(parsedData, '[MetricName]')

    const filteredGroupedData = Object.entries(groupedData).filter(([index]) => {
      if (reportData.rawApiParameters.truMetric) {
        let metricName = reportData.rawApiParameters.truMetric
        if (metricName === 'ease-of-use') {
          metricName = metricName.split('-').join(' ')
        }

        return index.toLowerCase().includes(metricName as string)
      }

      return true
    })

    const getColumn = (i: { [name: string]: string | number }) => {
      return [i['[Start Date]'], getNumberValue(i['[RatingQuantity]'])]
    }

    for (const [index, item] of filteredGroupedData) {
      formattedData.push({
        name: index,
        type: index.toLowerCase().includes('trurating') ? 'line' : 'column',
        data: Array.isArray(item) ? item.map(i => getColumn(i)) : getColumn(item)
      })
    }

    return {
      series: formattedData
    }
  } else if (
    [
      'CustomQuestionBinaryHeatmap',
      'CoreQuestionBinaryHeatmap',
      'CustomQuestionScaledHeatmap',
      'CoreQuestionScaledHeatmap',
      'CustomMetricMCHeatmap'
    ].includes(reportMeta.reportId)
  ) {
    parsedData = parseData(
      columns,
      data,
      false,
      (row: ServerDataRow) => {
        if (['string', 'object'].includes(row.type)) {
          return row.formattedValue
        }

        if (row.type === 'decimal') {
          return row.rawValue
        }

        return row.value
      }
    )

    const seriesColumns: Array<string> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

    return {
      seriesColumns,
      data: parsedData
    }
  } else if (reportMeta.reportId === 'PowerUserStoreComparison') {
    // TODO: Remove this when ALDI configuration is updated in the database to match seed data
    const services = truMetricsArray.map(metric => metric.displayName).map(metric => `[${metric}]`.toLowerCase())
    const reformattedData = parsedData
      .filter(row => row['[HasData]'])
      .map(row => {
        for (const rowKey of Object.keys(row)) {
          if (services.includes(rowKey.toLowerCase())) {
            row[rowKey] = getNumberValue(row[rowKey]).toFixed(2)
          }
        }

        const reformattedRow: { [key: string]: string } = {
          ...row,
          '[TruPromoter]': getNumberValue(row['[TruPromoter]']).toFixed(2),
          '[ResponseRate]': getNumberValue(row['[ResponseRate]']).toFixed(2) + '%'
        }

        if (row['[Atv]'] > 0 && row['[Atv]'] && row['[CurrencySymbol]']) {
          reformattedRow['[Atv]'] = row['[CurrencySymbol]'] + getNumberValue(row['[Atv]']).toFixed(2)
        }

        return reformattedRow
      })

    let irrelevantColumns = ['[CurrencySymbol]', '[HasData]', '[QuestionShare]']

    if (!(reportData.rawApiParameters.touchpoint as Array<string>).includes('instore')) {
      irrelevantColumns = [...irrelevantColumns, '[Service]']
    }

    if (!(reportData.rawApiParameters.touchpoint as Array<string>).includes('online')) {
      irrelevantColumns = [...irrelevantColumns, '[Ease of Use]']
    }

    const relevantColumns = columns
      .filter(
        col => !irrelevantColumns.includes(col.dimensionCaption)
      )
      .map(col => {
        let headerClasses
        let bodyClasses

        if (col.dimensionCaption === '[StoreRank]' || col.dimensionCaption === '[Organisation]') {
          bodyClasses = 'font-medium '
        }

        const defaultSortedColumns = ['[TruRating]', '[Score]']
        if (defaultSortedColumns.includes(col.dimensionCaption)) {
          bodyClasses = ' bg-gradient-to-r from-trublue/10 to-service/10 font-medium '
          headerClasses = ' bg-gradient-to-r from-trublue/80 to-service/80 text-white '
        }

        return {
          ...col,
          headerClasses,
          bodyClasses,
          sortable: !['[StoreRank]'].includes(col.dimensionCaption),
          useTranslation: ['[StoreRank]', '[RatingQuantity]', '[ResponseRate]', '[Atv]'].includes(col.dimensionCaption),
          onlyTranslateLocale: 'en'
        }
      })

    return {
      columns: relevantColumns,
      data: reformattedData
    }
  } else if (['MetricComparisonByCollector', 'MetricComparisonByOutletTag'].includes(reportMeta.reportId)) {
    const services = truMetricsArray.map(metric => metric.displayName).map(metric => `[${metric}]`.toLowerCase())
    const reformattedData = parsedData.map(row => {
      for (const rowKey of Object.keys(row)) {
        if (services.includes(rowKey.toLowerCase())) {
          row[rowKey] = getNumberValue(row[rowKey]).toFixed(2)
        }
      }

      const reformattedRow: { [key: string]: string } = {
        ...row,
        '[TruPromoter]': getNumberValue(row['[TruPromoter]']).toFixed(2),
        '[ResponseRate]': getNumberValue(row['[ResponseRate]']).toFixed(2) + '%'
      }

      if (row['[Atv]'] > 0 && row['[Atv]'] && row['[CurrencySymbol]']) {
        reformattedRow['[Atv]'] = row['[CurrencySymbol]'] + getNumberValue(row['[Atv]']).toFixed(2)
      }

      return reformattedRow
    })

    let irrelevantColumns = ['[CurrencySymbol]', '[QuestionShare]']

    if (!(reportData.rawApiParameters.touchpoint as Array<string>).includes('instore')) {
      irrelevantColumns = [...irrelevantColumns, '[Service]']
    }

    if (!(reportData.rawApiParameters.touchpoint as Array<string>).includes('online')) {
      irrelevantColumns = [...irrelevantColumns, '[Ease of Use]']
    }

    const relevantColumns = columns
      .filter(
        col => !irrelevantColumns.includes(col.dimensionCaption)
      )
      .map(col => {
        let headerClasses
        let bodyClasses

        if (
          col.dimensionCaption === '[Rank]'
          || col.dimensionCaption === '[RatingCollectorName]'
          || col.dimensionCaption === '[OutletTagValue]') {
          bodyClasses = 'font-medium '
        }

        const defaultSortedColumns = ['[TruRating]', '[Score]']
        if (defaultSortedColumns.includes(col.dimensionCaption)) {
          bodyClasses = ' bg-gradient-to-r from-trublue/10 to-service/10 font-medium '
          headerClasses = ' bg-gradient-to-r from-trublue/80 to-service/80 text-white '
        }

        return {
          ...col,
          headerClasses,
          bodyClasses,
          sortable: !['[Rank]'].includes(col.dimensionCaption),
          useTranslation: [
            '[Rank]',
            '[RatingQuantity]',
            '[RatingCollectorName]',
            '[OutletTagValue]',
            '[ResponseRate]',
            '[Atv]'].includes(col.dimensionCaption),
          onlyTranslateLocale: 'en'
        }
      })

    return {
      columns: relevantColumns,
      data: reformattedData
    }
  } else if (reportMeta.reportId.includes('OutletList')) {
    let formattedData = parsedData
    let columnList: Array<HubTableColumn> = columns
      .filter(
        col =>
          ![
            '[HasData]',
            '[RatingShare]',
            '[ResponseOptionSortedBy]',
            '[ResponseOptionLabel]',
            '[Response %]',
            '[ResponseRatingQuantity]',
            '[RatingQuantity]',
            '[Index]',
            '[QuestionLabel]'
          ].includes(col.dimensionCaption)
      )
      .map(col => {
        let headerClasses
        let bodyClasses

        if (
          col.dimensionCaption === '[Rank]'
          || col.dimensionCaption === '[OutletRank]'
          || col.dimensionCaption === '[Comparison Group Tag Value]'
        ) {
          bodyClasses = 'font-medium '
        }

        return {
          ...col,
          formattedCaption: col.dimensionCaption.replace(/[[\]]/g, ''),
          headerClasses,
          bodyClasses,
          useTranslation: true,
          sortable: !['[OutletRank]', '[Rank]'].includes(col.dimensionCaption)
        }
      })

    if (reportMeta.reportId.includes('Binary') || reportMeta.reportId.includes('CustomMetric')) {
      const groupByColumn: string = reportMeta.reportId.includes('CustomMetric')
        ? '[Organisation]'
        : '[Comparison Group Tag Value]'
      const labelColumn: string = reportMeta.reportId.includes('CustomMetric')
        ? '[QuestionLabel]'
        : '[ResponseOptionLabel]'

      const groupedData = groupBy(parsedData, groupByColumn)
      formattedData = []
      columnList = columnList.filter(c => c.dimensionCaption !== '[Score]')

      for (const item of Object.values(groupedData)) {
        const formattedItem = { ...item[0] }

        for (const x of item) {
          formattedItem[x[labelColumn]] = x['[Response %]']
            ? getNumberValue(x['[Response %]']).toFixed(2) + '%'
            : getNumberValue(x['[Score]']).toFixed(2) + '%'
          formattedItem[x[labelColumn + ' Quantity']] = x['[ResponseRatingQuantity]']

          if (!columnList.some(col => col.formattedCaption === x[labelColumn])) {
            columnList.push({
              dimensionCaption: x[labelColumn],
              formattedCaption: x[labelColumn],
              columnDataFormat: null,
              columnDataType: null,
              columnType: 'String',
              useTranslation: false,
              sortable: true,
              headerClasses:
                x[labelColumn] === x['[ResponseOptionSortedBy]']
                  ? ' bg-gradient-to-r from-trublue/80 to-service/80 text-white rounded-t '
                  : '',
              bodyClasses:
                x[labelColumn] === x['[ResponseOptionSortedBy]']
                  ? ' bg-gradient-to-r from-trublue/10 to-service/10 font-medium '
                  : ''
            })
          }
        }

        formattedData.push(formattedItem)
      }
    } else {
      const defaultSortedColumns = ['[TruPromoter]', '[Score]']
      columnList = columnList.map(col => ({
        ...col,
        bodyClasses: defaultSortedColumns.includes(col.dimensionCaption) ? ' bg-gradient-to-r from-trublue/10 to-service/10 font-medium ' : col.bodyClasses,
        headerClasses: defaultSortedColumns.includes(col.dimensionCaption) ? ' bg-gradient-to-r from-trublue/80 to-service/80 text-white ' : col.headerClasses
      }))
    }

    const reformattedData = formattedData
      .filter(row => row['[HasData]'] || reportMeta.reportId === 'CustomMetricMCOutletList')
      .map(row => ({
        ...row,
        '[TruPromoter]': getNumberValue(row['[TruPromoter]']).toFixed(2),
        '[Fans]': getNumberValue(row['[Fans]']).toFixed(2) + '%',
        '[Indifferent]': getNumberValue(row['[Indifferent]']).toFixed(2) + '%',
        '[Disappointed]': getNumberValue(row['[Disappointed]']).toFixed(2) + '%',
        '[Response %]': row['[Response %]'] ? getNumberValue(row['[Response %]']).toFixed(2) + '%' : undefined
      }))

    return {
      columns: columnList,
      data: reformattedData
    }
  } else if (reportMeta.reportId === 'CustomMetricMCOutletHighestLowest') {
    const groupedData = groupBy(parsedData, '[GroupType]')
    const numberOfTables = Object.keys(groupedData).length
    const tableData: Array<HubTable> = []

    for (const [index, item] of Object.entries(groupedData)) {
      const tableItem: HubTable = {
        id: reportMeta.reportId + '.' + index,
        tableTitle: numberOfTables > 1 ? index : undefined,
        includeSearch: false,
        includePagination: false,
        bordered: true,
        data: item.toSorted((a, b) => a['[Rank]'] - b['[Rank]']),
        columns: columns
          .filter(c =>
            !['[GroupType]', '[Rank]'].includes(c.dimensionCaption)
          )
          .map(c => ({
            ...c,
            sortable: false,
            useTranslation: ['[CustomMetricTotalRatingQuantity]', '[CustomMetricScore]'].includes(c.dimensionCaption),
            bodyClasses: c.dimensionCaption === '[Organisation]' ? 'font-medium' : undefined
          }))
      }

      tableData.push(tableItem)
    }

    return { data: tableData }
  } else if (reportMeta.reportId.includes('OutletHighestLowest')) {
    const groupedData = groupBy(parsedData, '[HighestLowestGroup]')
    const tableData: Array<HubTable> = []

    for (const [index, item] of Object.entries(groupedData)) {
      let formattedData = item.toSorted((a, b) => a['[OutletRank]'] - b['[OutletRank]'])
      let columnList: Array<HubTableColumn> = columns
        .filter(
          c =>
            !['[HighestLowestGroup]', '[OutletRank]', '[ResponseOptionSortedBy]', '[Binary Option]'].includes(
              c.dimensionCaption
            )
        )
        .map(c => ({
          ...c,
          sortable: false,
          useTranslation: c.dimensionCaption === '[RatingQuantity]',
          onlyTranslateLocale: 'en',
          bodyClasses: c.dimensionCaption === '[Organisation]' ? 'font-medium' : undefined
        }))

      if (reportMeta.reportId.includes('Binary')) {
        const groupedItem = groupBy(item, '[Organisation]')
        formattedData = []
        columnList = columnList.filter(
          c => !['[ResponseOptionLabel]', '[Response %]', '[RatingQuantity]'].includes(c.dimensionCaption)
        )

        for (const item of Object.values(groupedItem)) {
          const formattedItem = { ...item[0] }

          for (const x of item) {
            formattedItem[x['[ResponseOptionLabel]']] = getNumberValue(x['[Response %]']).toFixed(2) + '%'
            formattedItem[x['[ResponseOptionLabel]'] + ' Quantity'] = x['[ResponseRatingQuantity]']

            if (!columnList.some(col => col.formattedCaption === x['[ResponseOptionLabel]'])) {
              columnList.push({
                dimensionCaption: x['[ResponseOptionLabel]'],
                formattedCaption: x['[ResponseOptionLabel]'],
                columnDataFormat: null,
                columnDataType: null,
                columnType: 'String',
                useTranslation: false,
                sortable: true,
                headerClasses:
                  x['[ResponseOptionLabel]'] === x['[ResponseOptionSortedBy]']
                    ? ' bg-gradient-to-r from-trublue/80 to-service/80 text-white '
                    : '',
                bodyClasses:
                  x['[ResponseOptionLabel]'] === x['[ResponseOptionSortedBy]']
                    ? ' bg-gradient-to-r from-trublue/10 to-service/10 font-medium '
                    : ''
              })
            }
          }

          formattedData.push(formattedItem)
        }
      }

      const tableItem: HubTable = {
        id: reportMeta.reportId + '.' + index,
        tableTitle: index,
        includeSearch: false,
        includePagination: false,
        bordered: true,
        data: formattedData,
        columns: columnList,
        onlyTranslateLocale: 'en'
      }

      tableData.push(tableItem)
    }

    return {
      data: tableData
    }
  } else if (reportMeta.reportId.includes('KeyTimesOfDay')) {
    interface KeyTimesOfDayResponse {
      '[Day Of Week]': string
      '[Day Part Value]': string
      '[KPI Label]': string
      '[Day Part Name]': string
      '[Day Part Display Label]': string
    }

    parsedData = parseData(
      columns,
      data,
      false,
      (row: ServerDataRow) => {
        if (['string', 'object'].includes(row.type)) {
          return row.formattedValue
        }

        return row.value
      }
    )

    const highestValue = parsedData.find(row => row['[KPI Label]'] === 'High') as KeyTimesOfDayResponse
    const lowestValue = parsedData.find(row => row['[KPI Label]'] === 'Low') as KeyTimesOfDayResponse

    return {
      highestValue: {
        day: highestValue['[Day Of Week]'],
        value: highestValue['[Day Part Value]'],
        time: highestValue['[Day Part Display Label]']
      },
      lowestValue: {
        day: lowestValue['[Day Of Week]'],
        value: lowestValue['[Day Part Value]'],
        time: lowestValue['[Day Part Display Label]']
      }
    }
  }

  return parsedData
}
